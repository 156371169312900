:root {
  color-scheme: dark;
  --neutral-11: #15171e;
  --neutral-10: #0c0e13;
  --neutral-9: #111219;
  --neutral-8: #1c1e22;
  --neutral-7: #1e1f25;
  --neutral-6: #23252b;
  --neutral-5: #2c2e34;
  --neutral-4: #737478;
  --neutral-3: #7e7f83;
  --neutral-2: #8a8b8f;
  --neutral-1: #ffffff;
  --neutral-6-transparent: rgba(35, 37, 43, 0.9);
  --neutral-6-transparent-8: rgba(35, 37, 43, 0.8);
  --neutral-6-transparent-7: rgba(35, 37, 43, 0.7);
  --neutral-8-transparent: rgba(28, 30, 34, 0.9);
  --neutral-8-transparent-8: rgba(28, 30, 34, 0.8);
  --black: #000;
  --white: #fff;
  --accent: #d74691;
  --accent-light: #d65a9a;
  --green: #83b547;
  --green-hover: #9ddb51;
  --blue: #2c97de;
  --blue-hover: #36a9f5;
  --facebook-blue: #3c5a99;
  --google-blue: #4285f4;
  --red: #cc3e4a;
  --red-hover: #e8414f;
  --patreon-red: #e85b46;
  --discord-purple: #5865f2;
  --orange: #e76d3b;
  --yellow: #c1b947;
  --purple: #772caa;
  --blue-light: #88b3ce;
  --green-light: #9ac16a;
  --orange-light: #db815e;
  --red-light: #c45e66;
  --yellow-light: #e2da5f;
  --yellow-dark: #87801b;
  --copper: #e76d3b;
  --silver: #dbdbdb;
  --electrum: #a2bade;
  --gold: #e2da5f;
  --platinum: #fff;
  --acid: #83b547;
  --bludgeoning: #a5aab4;
  --cold: #3b93ce;
  --fire: #e63f21;
  --force: #d74691;
  --lightning: #88b3ce;
  --necrotic: #772caa;
  --piercing: #a5aab4;
  --poison: #83b547;
  --psychic: #e63f21;
  --radiant: #e2da5f;
  --slashing: #a5aab4;
  --thunder: #e63f21;
  --font-size: 15px;
  --header-height: 50px;
  --border-radius: 4px;
  --border-radius-small: 3px;
}

[data-theme=light] {
  color-scheme: light;
  --neutral-11: #ffffff;
  --neutral-10: #eaf1fb;
  --neutral-9: #f2f6fc;
  --neutral-8: #f6f8fc;
  --neutral-7: #eaf1fb;
  --neutral-6: #ffffff;
  --neutral-5: #c2e7ff;
  --neutral-4: #8cb7d4;
  --neutral-3: #638ba6;
  --neutral-2: #0d2d43;
  --neutral-1: #060607;
  --neutral-6-transparent: rgba(255, 255, 255, 0.9);
  --neutral-6-transparent-8: rgba(255, 255, 255, 0.8);
  --neutral-6-transparent-7: rgba(255, 255, 255, 0.7);
  --neutral-8-transparent: rgba(246, 248, 252, 0.9);
  --neutral-8-transparent-8: rgba(246, 248, 252, 0.8);
  --green: #0c940c;
  --green-hover: #008a00;
  --blue: #0078d3;
  --blue-hover: #0263ad;
  --red: #d30100;
  --red-hover: #b30202;
  --orange: #ffaa44;
  --radiant: #e2da5f;
}

/* 1. Variables */
/*********************************************/
/**
* Quasar
* @see https://v1.quasar.dev/style/sass-scss-variables#variables-list
*/
h3 {
  font-size: 25px !important;
  margin: 40px 0 20px 0 !important;
}

h4 {
  font-size: 18px;
}

.base_abilities {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: -5px;
  flex-wrap: wrap;
}
.base_abilities .ability {
  margin: 5px;
  text-align: center;
  min-width: 90px;
  width: 130px;
}
.base_abilities .ability.computed {
  max-width: 100px;
}
.base_abilities .ability .label {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 12px;
}
.base_abilities .ability .q-field {
  text-align: center !important;
  font-size: 25px;
  font-weight: bold;
}
.base_abilities .ability .score {
  font-size: 45px;
  color: var(--white);
}

.table {
  max-width: 300px;
  border-spacing: 1px;
}
.table td {
  text-align: center;
}

.ability-rolls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -5px;
}
.ability-rolls .score {
  background-color: var(--neutral-8);
  margin: 5px;
  min-width: 230px;
}
.ability-rolls .score .rolls {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  padding: 10px;
}
.ability-rolls .score .rolls .roll {
  background: var(--neutral-9);
  width: 30px;
  height: 30px;
  text-align: center;
  margin-right: 2px;
  color: var(--white);
}
.ability-rolls .score .rolls .roll.rolled {
  background-color: var(--blue);
}
.ability-rolls .score .rolls .roll.ignore {
  opacity: 0.5;
}
.ability-rolls .score .rolls .total {
  color: var(--white);
  text-align: right;
  font-size: 25px;
}